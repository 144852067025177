<template>
    <div class="wrapper" v-loading="loading">
        <div class="imgs-wrapper">
            <div class="imgs" v-for="(item,index) in list" :key="index">
                <el-image class="imgs-img" :src="item.photo_url" alt="" :preview-src-list="list.map(item=>item.photo_url)"></el-image>
                <div class="imgs-top imgs-text" @click="deleteImg(item)">{{ item.type == 1 ? '编辑' : '删除' }}</div>
                <div class="imgs-bottom imgs-text">{{ types[item.type] }}</div>
            </div>
        </div>
        <el-select v-model="selectType" size="small" placeholder="请选择上传照片类型" clearable style="margin-right: 10px">
            <el-option
                v-for="(val,key) in types"
                :key="key"
                :label="val"
                :value="key">
            </el-option>
        </el-select>
        <el-button size="small" :disabled="!selectType" type="primary" @click="handleOpen()">添加图片</el-button>
        <el-dialog title="添加图片" :visible.sync="dialogVisible" width="600px" :before-close="handleClose">
            <qiniu :isCropper="true" :width="525" :height="420" :imgKey="imgUrl" @uploadSuccess="uploadSuccess"></qiniu>
        </el-dialog>
    </div>
</template>
<script>
import Qiniu from '@/components/qiniu.vue'
import { getAlbumList, saveOrgAlbum, delOrgAlbum } from '../../../api/institutional.js';
export default {
    name: "OrgAlbum",
    data() {
        return {
            loading: true,
            orgId: this.$route.query.orgId,
            list: [], // 列表
            types: {
                1: '大门照片',
				2: '前台照片',
				3: '大厅照片',
				4: '教室照片',
				5: '上课照片',
				6: '荣誉照片',
				7: '学员照片',
				8: '其他照片',
            },
            // 添加图片
            selectType: '',  // 添加选中类型
            dialogVisible: false,
            imgUrl: '' // 上传图片地址
        }
    },
    components: {
        Qiniu
    },
    created() {
        this.getList()
    },
    methods: {
        // 获取列表
        getList() {
            getAlbumList({
                org_id: this.orgId,
            }).then((res) => {
                this.list = res.data ? res.data.sort((a,b)=>a.type-b.type) : []
                this.loading = false;
            }).catch((err) => {
                console.log(err)
            })
        },
        // 上传图片
        addImg() {
            saveOrgAlbum({
                org_id: this.orgId,
                type: this.selectType,
                photo_url: this.imgUrl
            }).then(() => {
                this.$message({ message: '添加成功', type: 'success' });
                this.getList()
            }).catch((err) => {
                console.log(err)
            })
        },
        // 删除照片
        deleteImg(item) {
            if(item.type == 1) { // 大门是编辑
                this.selectType = '1'
                this.handleOpen()
            }else { // 其他是删除
                this.$confirm('是否确定删除', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.deleteFun(item.id)
                }).catch(() => {});
            }
        },
        // 删除方法
        deleteFun(id) {
            delOrgAlbum({ id }).then(() => {
                this.$message({ message: '删除成功', type: 'success' });
                this.getList()
            }).catch((err) => {
                console.log(err)
            })
        },
        // 弹框打开
        handleOpen() {
            this.imgUrl = ''
            this.dialogVisible = true
        },
        // 弹框关闭
        handleClose() {
            this.dialogVisible = false
        },
        // 图片上传成功回调函数
        uploadSuccess(res) {
            this.imgUrl = this.$constant.qnUrl + '/' + res.key
            this.dialogVisible = false
            this.addImg()
        }
    }
};
</script>
<style lang="less" scoped>
.imgs-wrapper {
    display: flex;
    flex-wrap: wrap;
}
.imgs {
    position: relative;
    width: 200px;
    height: 160px;
    border-radius: 6px;
    margin: 0 10px 10px 0;
    overflow: hidden;
    &-img {
        width: 100%;
        height: 100%;
    }
    &-text {
        position: absolute;
        width: 100%;
        height: 30px;
        box-sizing: border-box;
        padding: 0 10px;
        line-height: 30px;
        color: #fff;
    }
    &-top {
        top: 0;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(3, 3, 3, 0.15) 100%);
        text-align: right;
        cursor: pointer;
    }
    &-bottom {
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(3, 3, 3, 0.4) 100%);
        text-align: left;
    }
}
</style>