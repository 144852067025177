<template>
    <div>
        <StkList :orgId="orgId"/>
    </div>
</template>
<script>
import StkList from '../../courseOrder/stkList'
export default {
    name: "OrgCourse",
    data() {
        return {
            orgId: this.$route.query.orgId,
        }
    },
    components: {
        StkList
    }
};
</script>