<template>
	<div class="wrapper">
		<el-page-header @back="$router.push({path: '/institutional', query: {group: '3',item: '3-1', keepAlive: '1'}})" content="机构详情"></el-page-header>
		<el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" style="margin-bottom: 10px;">
			<el-menu-item index="1" @click="activeIndex = 1">详情</el-menu-item>
			<el-menu-item index="2" @click="activeIndex = 2">视频库</el-menu-item>
			<el-menu-item index="3" @click="activeIndex = 3">课程库</el-menu-item>
			<el-menu-item index="4" @click="activeIndex = 4">优惠券</el-menu-item>
			<!-- <el-menu-item index="5" @click="activeIndex = 5">机构标签管理</el-menu-item> -->
			<el-menu-item index="6" @click="activeIndex = 6">机构员工</el-menu-item>
			<el-menu-item index="7" @click="activeIndex = 7">积分明细</el-menu-item>
			<el-menu-item index="8" @click="activeIndex = 8">点赞记录</el-menu-item>
			<el-menu-item index="9" @click="activeIndex = 9">机构粉丝</el-menu-item>
			<el-menu-item index="10" @click="activeIndex = 10">机构相册</el-menu-item>
		</el-menu>
		<OrgDetail v-if="activeIndex == 1"/>
        <OrgVideo v-if="activeIndex == 2"/>
        <OrgCourse v-if="activeIndex == 3"/>
        <OrgCoupon v-if="activeIndex == 4"/>
        <OrgTags v-if="activeIndex == 5"/>
		<OrgAdmin v-if="activeIndex == 6"/>
        <OrgCreditList v-if="activeIndex == 7"/>
        <OrgVote v-if="activeIndex == 8"/>
        <OrgFans v-if="activeIndex == 9"/>
		<OrgAlbum v-if="activeIndex == 10"/>
	</div>
</template>
<script>
	import OrgDetail from './org_detail.vue'
	import OrgVideo from './org_video.vue'
    import OrgCourse from './org_course.vue'
    import OrgAdmin from './org_admin.vue'
    import OrgCoupon from './org_coupon.vue'
	import OrgTags from './org_tags.vue'
	import OrgCreditList from './orgCreditList.vue'
    import OrgVote from './org_vote.vue'
	import OrgFans from './org_fans.vue'
	import OrgAlbum from './org_album'
	export default {
		props:{
		},
		data() {
			return {
				activeIndex: '1',
				activeIndex2: '1'
			};
		},
		components: {
			OrgDetail,
			OrgVideo,
			OrgCourse,
			OrgAdmin,
			OrgCoupon,
			OrgTags,
			OrgCreditList,
			OrgVote,
			OrgFans,
			OrgAlbum
		},
		mounted() {
			this.orgId = this.$route.query.orgId
		},
		methods: {
			linkTo(path){
				this.$router.push({path,query: {
						group: 3,
						item: '3-1',
						orgId:this.$route.query.orgId || this.orgId
					}})
			},
		}
	}
</script>
<style lang="less" scoped>
.el-menu-item.is-active {
    background: content-box;
}
</style>