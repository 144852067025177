<template>
    <div>
        <Stvideolist :orgId="orgId"/>
    </div>
</template>
<script>
import Stvideolist from '../../video/stvideolist.vue'
export default {
    name: "OrgVideo",
    data() {
        return {
            orgId: this.$route.query.orgId,
        }
    },
    components: {
        Stvideolist
    },
};
</script>
