<template>
    <div>
        <CouponList :orgId="orgId"/>
    </div>
</template>
<script>
import CouponList from '../../coupon/list'
export default {
    name: "OrgCoupon",
    data() {
        return {
            orgId: this.$route.query.orgId,
        }
    },
    components: {
        CouponList
    }
};
</script>