<template>
    <div>
        <StaffList :orgId="orgId"/>
    </div>
</template>
<script>
import StaffList from '../staff'
export default {
    name: "OrgAdmin",
    data() {
        return {
            orgId: this.$route.query.orgId,
        }
    },
    components: {
        StaffList
    }
};
</script>