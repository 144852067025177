<template>
    <div class="wrapper" v-loading="loading">
        <div v-if="!orgId" class="top-btn" ref="topBtn">
            <div>
                <el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"> </el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入内容" v-model="search.desp" clearable> </el-input>
                <el-button size="small" type="primary" @click="searchList()">搜索</el-button>
            </div>
            <div>
                <el-select class="w110" size="small" v-model="search.status" clearable @change="searchList()">
					<el-option :key="0" :value="0" label="下架"></el-option>
					<el-option :key="1" :value="1" label="上架"></el-option>
				</el-select>
				<span class="demonstration">创建时间：</span>
				<el-date-picker size="small" style="width: 230px;margin-right: 10px" @change="searchList()"
					v-model="search.time"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
                <el-button size="small" type="primary" @click="add()">添加</el-button>
            </div>
        </div>
        <el-table
            :data="list"
            :max-height="tableHeight"
            :header-cell-style="{background:'#f5f7fa'}"
            style="width: 100%">
            <el-table-column prop="id" label="视频id"></el-table-column>
            <el-table-column prop="member_id" label="发布人"></el-table-column>
            <el-table-column prop="created_at" label="创建时间">
                <template slot-scope="scope">{{ scope.row.created_at*1000 | formatDate }}</template>
            </el-table-column>
            <el-table-column label="视频图片">
                <template slot-scope="scope"> 
                   <img :src="scope.row.cover_url || ''" style="width: 50px" alt=""/>
                </template>
            </el-table-column>
            <el-table-column prop="desp" label="视频描述" show-overflow-tooltip></el-table-column>
            <el-table-column label="视频">
                <template slot-scope="scope"> 
                   <video :src="scope.row.video_url || ''" style="width: 50px" controls="controls"></video>
                </template>
            </el-table-column>
            <el-table-column prop="org_name" label="机构"></el-table-column>
            <el-table-column prop="duration" label="视频时长"></el-table-column>
            <el-table-column prop="video_size" label="视频大小">
                <template slot-scope="scope"> 
                   {{ scope.row.video_size || '--' }} M
                </template>
            </el-table-column>
            <el-table-column prop="member_id" label="创作人">
                <template slot-scope="scope"> 
                   {{ scope.row.member_name || '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="course_video" label="关联数量">
                <template slot-scope="scope"> 
                   课程数量{{ scope.row.relation_course_count }} &nbsp;&nbsp; 
                   优惠券数量{{ scope.row.relation_coupon_count }} &nbsp;&nbsp; 
                </template>
            </el-table-column>
            <el-table-column label="数据汇总">
                <template slot-scope="scope"> 
                   播放数{{ scope.row.play_count }} &nbsp;&nbsp; 
                   点赞{{ scope.row.vote_count }} &nbsp;&nbsp; 
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                   {{ scope.row.status == 0 ? '下架' : 
                   scope.row.status == 1 ? '上架' : '--' }}
                </template>
            </el-table-column>
            <el-table-column label="视频来源">
                <template slot-scope="scope">
                   {{ scope.row.video_from == 0 ? '非平台拍摄' : 
                   scope.row.video_from == 1 ? '平台拍摄' : '--' }}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-link type="primary" @click="handleUp(scope.row)">{{ scope.row.status != 1 ? '上架' : '下架' }}</el-link>
                    &nbsp;&nbsp;
                    <el-link type="primary" @click="handleEdit(scope.row.id)">编辑</el-link>
                    &nbsp;&nbsp;
                    <el-link type="primary" @click="handleDelete(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            v-if="total > 0"
            style="padding-top: 15px; text-align: center;"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="limit"
            layout="prev, pager, next, jumper"
            :total="total"
            >
        </el-pagination>
        
        <stvideoAdd ref="add" :dialogVisible="dialogVisible" :typeFlag="typeFlag" :id="id" @close="close" @getList="getList" @searchList="searchList"></stvideoAdd>
    </div>
</template>
<script>
import { videoList, onOrOffVideo, delVideo } from '@/api/courseOrder'
import stvideoAdd from './component/stvideoAdd.vue'
export default {
    name: "Stvideolist",
    props: {
        orgId: { // 机构详情里
            type: String,
            default: ''
        },
    },
    data() {
        return {
            loading: true,
            tableHeight: this.$constant.maxHeight,
            // 列表
            searchGist:  [{
                value: 'id',
                label: '视频id'
            }, {
                value: 'desp',
                label: '视频描述'
            },{
                value: 'org_id',
                label: '机构id'
            }, {
                value: 'org_name',
                label: '机构名'
            }],
            // 列表
            search: {
                search_gist: 'desp',
                desp: '',
                time: '',
                status: ''
            },
            total: 0,
            limit: 10,
            currentPage: 1,
            list: [],
            // 弹框
            typeFlag: 'add',
            dialogVisible: false,
            id: 0,
        }
    },
    components: {
        stvideoAdd
    },
    mounted() {
        this.getList()
        this.tableHeight = this.orgId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
        window.onresize = this.$common.debounce(()=>{
                this.tableHeight = this.orgId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
        }, 1000)
    },
    methods: {
        // 获取列表
        getList() {
            let data = {}
            if(this.orgId) {
                data = {
                    limit: this.limit,
                    page: this.currentPage,
                    org_id: this.orgId
                }
            }else {
                data = {
                    limit: this.limit,
                    page: this.currentPage,
                    status: this.search.status,
                    id: this.search.search_gist == 'id' ? this.search.desp : null,
                    desp: this.search.search_gist == 'desp' ? this.search.desp : null,
                    org_id: this.search.search_gist == 'org_id' ? this.search.desp : null,
                    org_name: this.search.search_gist == 'org_name' ? this.search.desp : null,
                    start_time: this.$common.setSearchTime(this.search.time)[0],
                    end_time: this.$common.setSearchTime(this.search.time)[1]
                }
            }
            videoList(data).then((res) => {
                this.total = res.data.total;
                this.list = res.data.data;
                this.loading = false
            }).catch((err) => {
                console.log(err)
            })
        },
        // 搜索
        searchList() {
            this.loading = true
            this.currentPage = 1;
            this.getList();
        },
        // 页码修改
        handleCurrentChange(val) {
            this.loading = true
            this.currentPage = val;
            this.getList();
        },
        // 新建
        add(){
            this.typeFlag = 'add'
            this.dialogVisible = true
        },
        // 编辑
        handleEdit(id) {
            this.$refs.add.getInfo(id)
            this.id = id
            this.typeFlag = 'edit'
            this.dialogVisible = true
        },
        // 删除
        handleDelete(id) {
            this.$alert('是否确定删除', '提示', {
                confirmButtonText: '确定',
                callback: action => {
					if(action == 'confirm'){
						this.deleteFun(id)
					}
                    
                }
            });
        },
        // 删除
        deleteFun(id){
            delVideo({ id }).then(()=>{
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.searchList()
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 上下架
        handleUp(row){
			let _this = this
			let content = row.status != 1 ? '上架' : '下架'
            this.$alert(`是否确定${content}`, '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    console.log(action)
                    if(action == 'confirm'){
                        onOrOffVideo({
                            id: row.id,
                            status: row.status == 1 ? 0 : 1
                        }).then(()=>{
                            _this.$message({
                                message: row.status != 1 ? '上架成功' : '下架成功',
                                type: 'success'
                            });
                            _this.searchList()
                        }).catch((err)=>{
                            console.log(err)
                        })
                    }
                }
            });
            
        },
        // 关闭弹框
        close() {
            this.dialogVisible = false
        },
    }
};
</script>
