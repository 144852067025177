<template>
	<div class="wrapper" v-loading="loading">
        <div v-if="!orgId" class="top-btn" ref="topBtn">
            <div>
                <el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"> </el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入内容" v-model="search.desp" clearable> </el-input>
                <el-button size="small" type="primary" @click="searchList()">搜索</el-button>
            </div>
			<div>
				<span class="demonstration">添加时间：</span>
				<el-date-picker size="small" style="width: 230px;margin-right: 10px" @change="searchList()"
					v-model="search.time"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
            </div>
        </div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="id" label="用户id"></el-table-column>
			<el-table-column prop="member_name" label="用户昵称"></el-table-column>
            <el-table-column prop="org_name" label="机构名"></el-table-column>
			<el-table-column prop="created_time" label="添加时间">
				<template slot-scope="scope">{{ scope.row.created_time*1000 | formatDate }}</template>
			</el-table-column>
			<el-table-column prop="real_name" label="备注名"></el-table-column>
			<el-table-column prop="role_name" label="权限"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="{type: 'remove',row: scope.row.id}">踢出机构</el-dropdown-item>
                            <el-dropdown-item :command="{type: 'edit_auth',row: scope.row.id,name:scope.row.role_name}">修改权限</el-dropdown-item>
                            <el-dropdown-item :command="{type: 'edit_remark',row: scope.row.id,name:scope.row.real_name}">修改备注</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog :title="opera=='edit_auth'?'修改权限':'修改备注'" :visible.sync="dialogVisible" :before-close="close" width="30%">
			<!-- 修改权限 -->
			<div v-if="opera=='edit_auth'">
				<el-form :model="formInline" class="demo-form-inline">
					<el-form-item label="当前权限">
						<div>{{ nameOrRole }}</div>
					</el-form-item>
					<el-form-item label="选择权限">
						<el-select v-model="formInline.region" multiple>
							<el-option
								v-for="(item,index) in role"
								:key="index"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">确定</el-button>
					</el-form-item>
				</el-form>
			</div>
			<!-- 修改昵称 -->
			<div v-else>
				<div style="font-size: 16px;margin-bottom: 20px;">当前备注:{{ nameOrRole }}</div>
				<el-input v-model="formInline.realName" placeholder="请输入备注" style="width: 50%;margin-right: 20px;"></el-input>
				<el-button type="primary" @click="onSubmit">确定</el-button>
			</div>
		</el-dialog>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange"
		:current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>
<script>
	import { userList, kickOut, orgRole, changRole, changRealName } from '@/api/institutional.js';
	export default {
        name: "Admin",
            props: {
            orgId: { // 机构详情里
                type: String,
                default: ''
            },
        },
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
                // 搜索
                searchGist:  [{
                    value: 'member_name',
                    label: '用户昵称'
                },{
                    value: 'org_name',
                    label: '机构名'
                }],
                search: {
                    search_gist: 'member_name',
                    desp: '',
                    time: '',
                },
				// 列表
				total: 0,
				limit: 20,
				currentPage: 1,
				list: [],
				// 弹出框
				id: 0,
				dialogVisible: false,
				opera: '',
				formInline: {
					realName: '',
					region: ''
				},
				role: '', //所有可选权限
				nameOrRole: '', //当前权限或则昵称
			}
		},
		mounted() {
			this.getList()
			this.tableHeight = this.orgId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.orgId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 操作回调
			handleCommand(command) {
				this.operation(command.type, command.row, command.name)
			},
			/*
			操作：
			type:{
				remove:踢出机构
				edit_auth:修改权限
				edit_remark：修改昵称
			}
			*/
			operation(type, id, nameOrRole) {
				if (type != 'remove') {
					this.dialogVisible = true,
						this.opera = type,
						this.id = id,
						this.nameOrRole = nameOrRole
					if (type == 'edit_auth') {
						orgRole().then(res => {
							this.role = res.data
						}).catch(rej => {
							console.log(rej)
						})
					}
				}
				if (type == 'remove') {
					this.$alert('确定要将此人踢出机构吗？', '提出机构', {
						confirmButtonText: '确定',
						callback: action => {
							if (action == 'confirm') {
								// 调用踢出方法
								kickOut({
									id: id
								}).then(() => {
									this.getList()
									this.close()
								}).catch(rej => {
									this.$message({ message: rej, center: true });
									this.close()
								})
							}
						}
					});
				}
			},
			onSubmit() {
				let title, content
				if (this.opera == 'edit_auth') {
					content = '确定要修改此人的权限吗？'
					title = '修改权限'

				} else {
					content = '确定要修改此人的备注吗？'
					title = '修改备注'
				}
				this.$alert(content, title, {
					confirmButtonText: '确定',
					callback: action => {
						if (action == 'confirm') {
							// 调用踢出方法
							this.opera == 'edit_auth' ? this.editAuth() : this.editRemark()
						}
					}
				});
			},
			//修改权限
			editAuth() {
				let a 
				a =this.role.filter(x=>{
					if((this.formInline.region).indexOf(x.id)!=-1){
						return x.name
					}
				}).map(x=>{return x.name})
				changRole({
					id:this.id,
					role_id:this.formInline.region,
					role_name:a
				}).then(()=>{ 
					this.getList()
					this.close()
				}).catch(rej=>{
					console.log(rej)
					this.close()
				})

			},
			//修改昵称
			editRemark() {
				changRealName({
					id: this.id,
					real_name: this.formInline.realName
				}).then(() => {
					this.close()
					this.getList()
				}).catch(rej => {
					this.close()
					console.log(rej)
				})
			},
			close() {
				this.dialogVisible = false
				this.opera = ''
				this.id = ''
				this.formInline = {
					auth: '',
					region: ''
				}
			},
			// 获取列表
			getList() {
				this.loading = true
				let data = {}
				if(this.orgId) {
					data = {
						org_id: this.orgId,
						limit: this.limit,
						page: this.currentPage,
					}
				}else {
					data = {
						limit: this.limit,
						page: this.currentPage,
						member_name: this.search.search_gist == 'member_name' ? this.search.desp : null,
						org_name: this.search.search_gist == 'org_name' ? this.search.desp : null,
						start_time: this.$common.setSearchTime(this.search.time)[0],
						end_time: this.$common.setSearchTime(this.search.time)[1]
					}
				}
				userList(data).then((res) => {
					this.total = res.data.total;
                    this.list = res.data.data;
                    this.loading = false
				}).catch((err) => {
					console.log(err, 222)
				})
			},
			// 筛选
            searchList() {
                this.currentPage = 1;
				this.getList();
            },
			// 页码修改
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		}
	};
</script>
