<template>
	<div class="dialog">
		<el-dialog :title="typeFlag =='add'?'添加':'编辑'" :visible.sync="dialogVisible" width="700px" :before-close="close" :close-on-click-modal='false'>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
				<el-form-item label="请选择" v-if="typeFlag=='add' && dialogVisible" prop="org_id">
					<OrgSelect @selectOrg="selectOrg"/>
				</el-form-item>
				<el-form-item v-else label="机构名称">
					<div>{{ echo_org_name }}</div>
				</el-form-item>
				<el-form-item label="请选择视频">
					<qiniu :videoKey='ruleForm.video_url' @uploadSuccess='uploadSuccess'></qiniu>
				</el-form-item>
				<el-form-item label="视频成功后返回url" prop="video_url">
					<el-input size="small" v-model="ruleForm.video_url"></el-input>
				</el-form-item>
				<el-form-item label="视频来源" prop="video_from">
					<el-radio-group v-model="ruleForm.video_from">
						<el-radio :label="1">平台拍摄</el-radio>
						<el-radio :label="0">非平台拍摄</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="视频发布人" prop="member_id">
					<el-select size="small" v-model="ruleForm.member_id" filterable clearable placeholder="请选择">
						<el-option
						v-for="item in memberList"
						:key="item.member_id"
						:label="item.member_name"
						:value="item.member_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="视频描述" prop="desp">
					<el-input size="small" type="textarea" :rows="2" v-model="ruleForm.desp"></el-input>
				</el-form-item>
				<el-form-item label="请选择课程" prop="course_video">
					<el-checkbox-group v-model="ruleForm.course_video">
						<el-checkbox v-for="item in courseList" :key="item.id" :label="item.id">{{ item.name || '--' }} (￥{{item.price}})</el-checkbox>
					</el-checkbox-group>
					<el-pagination v-if="courseListTotal > limit" style="padding: 6px 0" @current-change="handleCurrentChange2"
					:current-page.sync="coursecurrentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="courseListTotal">
					</el-pagination>
				</el-form-item>
				
				<el-form-item label="标签">
					<el-checkbox-group v-model="ruleForm.tags" >
						<el-checkbox v-for="item in tagsList" :key="item.id" :label="item.name">{{ item.name || '--' }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
					<el-button @click="close()">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>
	import { orgInfo } from '@/api/institutional.js';
	import { saveVideo, courseList, videoInfo } from '@/api/courseOrder'
	import qiniu from '@/components/qiniu.vue'
	import OrgSelect from '@/components/orgSelect.vue'
	export default {
		name: "StvideoAdd",
		components: {
			qiniu,
			OrgSelect
		},
		props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
			typeFlag: { // 判断是新建 编辑 详情
				type: String,
				default: 'add'
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				// 弹框列表
				limit: 10,
				courseList: [],
				coursecurrentPage: 1,
				courseListTotal: 0,
				tagsList: [], // 标签列表
				memberList: [], // 用户列表
				// 弹框新建
				echo_org_name: '',
				ruleForm: {
					video_url: '',
					desp: '',
					org_id: '',
					course_video: [],
					video_from: '',
					member_id: '',
					tags: []
				},
				rules: {
					org_id: [
						{ required: true, message: '请选择机构', trigger: 'change' }
					],
					video_url: [
						{ required: true, message: '请上传视频', trigger: 'blur' },
					],
					desp: [
						{ required: true, message: '请输入视频描述', trigger: 'blur' },
						// { min: 6, max: 30, message: '请输入6-30字的视频描述', trigger: 'blur' }
					],
					course_video: [
						{ required: true, message: '请选择课程', trigger: 'change' }
					],
					video_from: [
						{ required: true, message: '请选择视频来源', trigger: 'blur' }
					],
					// member_id: [
					// 	{ required: true, message: '请选择视频发布人', trigger: 'change' }
					// ],
				}
			}
		},
		methods: {
			// 选中机构
			selectOrg(org_id) {
				this.ruleForm.org_id = org_id
				Promise.all([this.getallCate(),this.getcourseList()]).then((res2)=>{
					this.setallCate(res2[0]) // 设置标签
					this.setcourseList(res2[1]) // 设置课程
				})
			},
			// 获取课程
			getcourseList(info) {
				return courseList({
					limit: this.limit,
					page: this.coursecurrentPage,
					org_id: info ? info.org_id : this.ruleForm.org_id
				})
			},
			// 设置课程
			setcourseList(res) {
				this.ruleForm.course_video = [] // 清空课程数据
				this.courseList = res.data.data
				this.courseListTotal = res.data.total
			},
			// 获取标签
			getallCate(info) {
				return orgInfo({org_id: info ? info.org_id : this.ruleForm.org_id})
			},
			// 设置标签
			setallCate(resList) {
				this.ruleForm.tags = [] // 清空标签数据
				this.tagsList = resList.data['tags'] ? resList.data['tags'].split(',').map(item=> {return {id:item,name:item}}) : []
				this.memberList = resList.data['org_user_list'] || []
			},
			handleCurrentChange2(val) {
				this.coursecurrentPage = val;
				Promise.all([this.getcourseList()]).then((res2)=>{
					this.courseList = res2[0].data.data
				})
			},
			// 获取数据
			getInfo(id) {
				videoInfo({
					id: id || this.id
				}).then((res) => {
					Promise.all([this.getallCate(res.data),this.getcourseList(res.data)]).then((res2)=>{
						this.setallCate(res2[0]) // 设置标签
						this.setcourseList(res2[1]) // 设置课程
						this.setInfo(res.data) // 设置详情
					})
				}).catch((err) => {
					console.log(err, 222)
				})
				this.$emit('close');
			},
			// 赋值
			setInfo(info) {
				// 不要问为啥又复制了一层，你删除了就知道会有啥问题了
				let _ruleForm = Object.assign({},this.ruleForm)
				_ruleForm.video_url = info.video_url
				_ruleForm.desp = info.desp
				_ruleForm.org_id = info.org_id
				_ruleForm.member_id = info.member_id ? info.member_id : ''
				this.echo_org_name = info.org_name
				_ruleForm.video_from = Number(info.video_from)
				_ruleForm.tags = info.tags ? info.tags.split(',') : []
				_ruleForm.course_video = info.course_video&&info.course_video.length > 1 ? info.course_video.split(',').map(item => Number(item)) :
					[Number(info.course_video) || '']
				this.ruleForm = Object.assign({},_ruleForm)
			},
			// 创建
            submitForm(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.save()
					} else {
						return false;
					}
				});
			},
			save(){
				let params = { ...this.ruleForm }
				if (this.typeFlag == 'edit') {
					params.id = this.id
				}
				saveVideo(params).then((res) => {
					this.$message({
						message: res.msg,
						type: res.code != 200 ? 'warning' : 'success'
					});
					this.typeFlag == 'edit' ? this.$emit('getList') : this.$emit('searchList');
					this.close();
				}).catch((err) => {
					this.$emit('close');
					console.log(err, 222)
				})
			},
			// 关闭
			close() {
				this.$emit('close');
				this.courseList= []
				this.tagsList = []
				this.ruleForm = {
					video_url: '',
					desp: '',
					org_id: '',
					course_video: [],
					video_from: '',
					member_id: '',
					tags: []
				}
			},
			uploadSuccess(e) {
				this.ruleForm.video_url = this.$constant.qnUrl + '/' + e.key
			}
		}
	};
</script>
<style lang="less" scoped>
.el-radio-group {
    vertical-align: baseline;
}
</style>