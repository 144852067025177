<template>
	<div v-loading="loading">
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="focus_time" label="操作时间"></el-table-column>
			<el-table-column prop="member_head_img" label="用户头像">
				<template slot-scope="scope">
					<img :src="scope.row.member_head_img || ''" style="width: 80px" alt="" />
				</template>
			</el-table-column>
			<el-table-column prop="member_id" label="用户id"></el-table-column>
			<el-table-column prop="member_name" label="用户昵称"></el-table-column>
			<el-table-column prop="video_id" label="关注视频id"></el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange"
		:current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>

<script>
	import { orgFans } from '@/api/institutional.js';
	export default {
		name: "OrgFans",
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
				orgId: this.$route.query.orgId,
				// 列表
				total: 0,
				limit: 10,
				currentPage: 1,
				list: [],
			}
		},
		mounted() {
			this.getList()
			this.tableHeight =this.$common.setHeight([], 250)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([], 250)
			}, 1000)
		},
		methods: {
			// 获取列表
			getList() {
				orgFans({
					org_id: this.orgId,
					limit: this.limit,
					page: this.currentPage,
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false;
				}).catch((err) => {
					console.log(err, 222)
				})
			},
			// 页码修改
			handleCurrentChange(val) {
				this.loading = true;
				this.currentPage = val;
				this.getList();
			},
		}
	};
</script>
