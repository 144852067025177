<template>
    <div>
        <SendadsDetail :orgId="orgId"/>
    </div>
</template>
<script>
import SendadsDetail from '../../marketing/sendadsDetail'
export default {
    name: "orgCreditList",
    data() {
        return {
            orgId: this.$route.query.orgId,
        }
    },
    components: {
        SendadsDetail
    }
};
</script>