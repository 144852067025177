<template>
	<div>
		<div class="flex">
			<!-- 左边 -->
			<el-form :inline='true' label-width="100px" disabled style="display: flex;flex-direction: column;">
				<el-form-item label="机构logo:">
					<img :src="detail.logo" style="width: 100px;"><img>
				</el-form-item>
				<el-form-item label="大门照片:">
					<img :src="detail.gate" style="width: 150px;"><img>
				</el-form-item>
				<el-form-item label="机构名称:">
					<div>{{detail.org_name}}</div>
				</el-form-item>
				<el-form-item label="机构slogan:">
					<div>{{detail.slogan}}</div>
				</el-form-item>
				<el-form-item label="机构电话:">
					<div>{{detail.org_phone}}</div>
				</el-form-item>
				<el-form-item label="机构管理员:">
					<div>{{detail.org_user_list[0].member_name || '--'}}</div>
				</el-form-item>
				<el-form-item label="管理员电话:">
					<div>{{detail.org_phone}}</div>
				</el-form-item>
				<el-form-item label="机构地址:">
					<div>{{detail.address}}{{detail.detail}}</div>
				</el-form-item>
				<el-form-item label="营业时间:">
					<div>{{detail.start_time}}-{{detail.end_time}}</div>
				</el-form-item>
				<el-form-item label="选择分类:">
					<div>{{detail.cates}}</div>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {orgInfo} from '../../../api/institutional.js';
	export default {
		name: "OrgDetail",
		props: {
		},
		data() {
			return {
				id: this.$route.query.orgId || '', 
				detail: {},
			};
		},
		mounted() {
			this.getOrgInfo()
		},
		methods: {
			// 获取机构详情
			getOrgInfo(){
				orgInfo({ org_id:this.id }).then(res=>{
					this.detail = res.data
				}).catch(rej=>{
					console.log(rej)
				})
			}
		}
	};
</script>

<style scoped>
	.flex {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.button {
		justify-content: flex-end;
		padding-bottom: 20px;
	}
</style>
