<template>
	<div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="vote_time" label="时间">
				<template slot-scope="scope">{{ scope.row.vote_time*1000 | formatDate }}</template>
			</el-table-column>
			<el-table-column prop="video_url" label="点赞视频">
				<template slot-scope="scope">
					<video :src="scope.row.video_url || ''" style="width: 80px" alt="" />
				</template>
			</el-table-column>
			<el-table-column prop="member_name" label="点赞人"></el-table-column>
			<el-table-column prop="member_phone" label="点赞人手机号"></el-table-column>
			<el-table-column prop="desp" label="操作内容"></el-table-column>
			<el-table-column prop="ad_score" label="操作">
				<template slot-scope="scope">
					<el-popconfirm confirm-button-text='好的' cancel-button-text='不用了' icon="el-icon-info" icon-color="red" title="确定删除吗？">
						<el-link type="primary" slot="reference" @click="showDetail(scope.row.id)">删除</el-link>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange"
		:current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>

<script>
	import { memberVoteList } from '@/api/base.js';
	export default {
		name: "OrgVote",
		data() {
			return {
				orgId: this.$route.query.orgId,
				tableHeight: this.$constant.maxHeight,
				// 列表
				total: 0,
				limit: 10,
				currentPage: 1,
				list: [],
			}
		},
		mounted() {
			this.getList()
			this.tableHeight =this.$common.setHeight([], 250)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([], 250)
			}, 1000)
		},
		methods: {
			// 获取列表
			getList() {
				memberVoteList({
					org_id: this.orgId,
					limit: this.limit,
					page: this.currentPage,
				}).then((res) => {
					console.log(res)
					this.total = res.data.total;
					this.list = res.data.data;
				}).catch((err) => {
					console.log(err, 222)
				})
			},
			// 页码修改
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		}
	};
</script>
